header {
  position: relative;
  background-color: black;

  .header_image {
    height: 100vh;
    width: 100%;
    object-fit: cover;
  }

  .header_container {
    width: 40%;
    padding: 40px;
    border-radius: 20px;
    position: absolute;
    top: 50%;
    left: 25%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    background-color: rgba(0, 0, 0, 0.8);

    .header_text {
      line-height: 1.7;
      color: white;
      font-size: 25px;
      font-family: "PT Serif", serif;
    }

    .header_button {
      font-size: 20px;
      font-weight: bold;
      background-color: blue;
      color: white;
      border-radius: 20px;
      border: none;
      padding: 10px 20px;
    }
  }
}

@media screen and (max-width: 1060px) {
  header {
    .header_container {
      width: 80%;
      left: 50%;

      .header_text {
        font-size: 19px;
      }
    }
  }
}
