.contacts {
  padding-top: 150px;

  .g-4 {
    width: 100%;
    margin-bottom: 50px;
  }

  .contacts_working_hours {
    background-color: white;
    width: 100%;
    height: 100%;
    padding: 40px;
    border-radius: 10px;
    -webkit-box-shadow: 10px 13px 27px 4px #000000;
    box-shadow: 10px 13px 27px 4px #000000;

    h6 {
      font-weight: bold;
    }
  }

  .contacts_container {
    h5 {
      text-align: center;
      font-size: 25px;
      padding: 15px 40px 0;
      font-family: "PT Serif", serif;
    }

    .contacts_box {
      -webkit-box-shadow: 10px 13px 27px 4px #000000;
      box-shadow: 10px 13px 27px 4px #000000;
      width: 100% !important;

      font-size: 20px;
      background: white;
      line-height: 1.7;
      margin-bottom: 20px;
      border-radius: 10px;

      form {
        display: flex;
        flex-direction: column;
        padding: 0 40px 40px 40px;
        border-radius: 10px;

        label {
          padding-top: 10px;
        }

        .contacts_input {
          border-radius: 10px;
          color: white;
        }

        .button {
          background-color: #44f;
          border-radius: 20px;
          margin-top: 20px;
          color: white;
          font-weight: bold;
        }
      }
    }
  }
}

.iframe_container {
  width: 100%;

  h6 {
    padding-top: 15px;
    text-align: center;
    font-weight: bold;
    font-size: 30px;
  }

  iframe {
    -webkit-box-shadow: 10px 13px 27px 4px #000000;
    box-shadow: 10px 13px 27px 4px #000000;
    border-radius: 10px;
  }
}

@media screen and (max-width: 1300px) {
  .g-4 {
    margin: 0 auto !important;
    margin-bottom: 50px !important;
  }
  .contacts_box {
    width: 80% !important;
    right: 50% !important;
    margin: 0 auto;
  }
}
