.services_image_container {
  .services_container {
    padding-top: 120px;

    .card {
      -webkit-box-shadow: 10px 13px 27px 4px #000000;
      box-shadow: 10px 13px 27px 4px #000000;
      .title {
        text-align: center;
        font-weight: bold;
      }
      .description {
        font-weight: bold;
        height: 190px;
        font-family: "PT Serif", serif;
        overflow: auto;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .services_image_container {
    .g-4 {
      margin: 0 auto;
    }
    .services_container {
      .card {
        width: 90%;
        margin: 0 auto;
      }
    }
  }
}
