.product_image_container {
  background-image: url("../../images/products.jpg");
  background-repeat: repeat;

  .products_container {
    padding: 110px 0 50px 0;

    .card {
      -webkit-box-shadow: 10px 13px 27px 4px #000000;
      box-shadow: 10px 13px 27px 4px #000000;
      margin: 5px;
      .title {
        text-align: center;
        font-weight: bold;
      }
      .description {
        float: right;
        font-weight: bold;
      }
    }

    .button_container {
      display: flex;
      justify-content: center;
      padding: 40px;
      .products_button {
        font-size: 20px;
        font-weight: bold;
        background-color: blue;
        color: white;
        border-radius: 20px;
        border: none;
        padding: 10px 20px;
        -webkit-box-shadow: 10px 13px 27px 4px #000000;
        box-shadow: 10px 13px 27px 4px #000000;
      }
    }
  }
}

@media screen and (max-width: 575px) {
  .product_image_container {
    .g-4 {
      margin: 0 auto;
    }
    .products_container {
      .card {
        width: 80%;
        margin: 0 auto;
      }
    }
  }
}
