.about_container {
  background-image: url("../../images/footer.png");
  background-size: cover;
  background-repeat: no-repeat;
  .about_box {
    display: flex;
    justify-content: center;
    padding-top: 100px;

    .about_text {
      background-color: white;
      padding: 30px;
      border-radius: 10px;
      font-family: "PT Serif", serif;
      margin-bottom: 80px;
      width: 50%;
      -webkit-box-shadow: 10px 13px 27px 4px #000000;
      box-shadow: 10px 13px 27px 4px #000000;
    }
  }
}

@media screen and (max-width: 850px) {
  .about_text {
    width: 90% !important;
  }
}
